<script>
  import store from '@/store'
  import { SCHEDULE_LEAVE_REQUEST, SAVE_EDITED_LEAVE_REQUEST } from '@/store/action-type'
  import { APPROVER_SEARCH_STARTED_EVENT, APPROVER_SELECTED_EVENT, APPROVER_SEARCH_CLEARED_EVENT } from '@/utils/constants'
  import { employeeService } from '@/services'

  import { ELeaveError } from '@/utils/errors'

  import Enums from '@/utils/enums'
  import { WorkLocationConstants, EmployeeTypeConstants } from '@/utils/constants'
  import ErrorMessages from '@/utils/errors/messages'


  export default {
    data() {
      return {
        showLeaveStartDateWarning: false,
        isSavingRequest: false,
        isSearchingEmployees: false,
        isCompleted: false,
        pageIsFullyLoaded: false,
        requestStates: Enums.RequestState,
        requestState: Enums.RequestState.New,
        searchedEmployees: [],
        searchedApprovers: [],
        errorMessageIfRequestCannotBeRaisedForEmployee: null,
        approverSearchClearedEvent: APPROVER_SEARCH_CLEARED_EVENT,
        approverSearchStartedEvent: APPROVER_SEARCH_STARTED_EVENT,
        approverSelectedEvent: APPROVER_SELECTED_EVENT,
      }
    },
    computed: {
      leaveRequestCanBeRaisedForEmployee() {
        if (this.request.employeeInfo.employeeId) {
          if (this.request.employeeInfo.employeeType && (this.request.employeeInfo.employeeType == EmployeeTypeConstants.REGULAR || this.request.employeeInfo.employeeType == EmployeeTypeConstants.EXPATRIATE)) {
            if (this.request.employeeInfo.locationId == WorkLocationConstants.FOREIGN_SERVICE) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeBeingOnForeignService, this.request.leaveType)
              return false
            }
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return true
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.leaveType)
          return false
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      }
    },
    watch: {
      errorMessageIfRequestCannotBeRaisedForEmployee(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.showErrorMessage(newValue)
          })
        }
        else {
          this.closeMessage()
        }
      }
    },
    methods: {
      searchEmployees(searchString) {
        const self = this
        self.resetData()
        self.isSearchingEmployees = true
        self.closeMessage()
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            let filteredEmployees = []
            if (self.currentUser.isAdmin) {
              filteredEmployees = result.data
            }
            self.searchedEmployees = [...filteredEmployees]
          }
          else {
            self.searchedEmployees = [{ employeeId: null, fullName: 'No results' }]
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          self.isSearchingEmployees = false
        })
      },
      clearApprover() {
        const self = this
        self.request.intendedApproverId = ''
        self.request.intendedApproverName = ''
      },
      searchApprovers(searchString) {
        const self = this
        self.closeMessage()
        self.request.intendedApproverId = ''
        self.request.intendedApproverName = ''
        self.searchedApprovers.splice(0, self.searchedApprovers.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            self.searchedApprovers = [...result.data]
          }
        })
      },
      selectApprover(approver) {
        this.request.intendedApproverId = approver.employeeId
        this.request.intendedApproverName = approver.fullName
      },
      rejectStartDate() {
        this.request.startDate = ''
        this.showLeaveStartDateWarning = false
      },
      validateRequest() {
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.request.intendedApproverId) {
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.request.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          //if ((this.request.intendedApproverId.toLowerCase() === this.currentUser.employeeId.toLowerCase()) && (this.currentUser.employeeId.toLowerCase() === this.employeeInfo.employeeId.toLowerCase())) {
          //  approverField.setCustomValidity("Invalid approver")
          //  invalidApprover = true
          //}
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const requestForm = document.getElementById('leaveEntryForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            "input.form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return false
        }
        else {
          return true
        }
      },
      submitRequest() {
        const self = this
        self.closeMessage()
        const requestForm = document.getElementById('leaveEntryForm')
        if (self.validateRequest()) {
          //self.request.duration = 40
          //self.request.employeeInfo.employeeId = 'mobolaji.fakeyede@chevron.com'
          //self.request.physicianId = 'OMNO'
          //self.request.intendedApproverId = 'MNYW'
          //self.request.employeeInfo.employeeId = 'OMNO'
          //self.request.childAddressBookNumber = 1091965
          self.closeMessage()
          self.isSavingRequest = true
          let data = { request: self.request }
          if (!self.request.id) {
            data.employeeId = self.employeeInfo.employeeId
          }
          else {
            data.comment = self.comment
          }
          let operation = self.request.id ? SAVE_EDITED_LEAVE_REQUEST : SCHEDULE_LEAVE_REQUEST
          store.dispatch(operation, data)
            .then(() => {
              self.requestState = Enums.RequestState.Submitted
              self.showSuccessMessage('request submitted successfully')
              self.isCompleted = true
              self.resetData()
            })
            .catch((error) => {
              console.log(error.appResultCode)
              if (error instanceof ELeaveError) {
                console.log(error.appResultCode)
                self.showErrorMessage(ErrorMessages.getErrorMessageByErrorCode(error.appResultCode))
              }
              else {
                self.showErrorMessage(error)
              }
            }).finally(() => {
              self.isSavingRequest = false
            })
        }
      },
      startNewRequest() {
        this.isCompleted = false
        this.closeMessage()
      },
      filterEmployeesByProperty(employees, propertyName, expectedValue) {
        if (employees && employees.length) {
          return employees.filter(employee => employee[propertyName] === expectedValue)
        }
      }
    },
    mounted() {
      const self = this
      if (self.preMountedSetup) {
        self.preMountedSetup()
      }

    }
  }

</script>
