<template>
  <div id="fieldVacationScheduleOptionView" class="text-left">
    <transition name="slide-fade">
      <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
    </transition>
    <div class="row">
      <div class="col">
        <h4>field vacation schedule option</h4>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <fieldset v-bind:disabled="isSaving">
          <employee-autocomplete v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="searchedEmployees" id="emp_search" :sData="request.employeeInfo.fullName" v-bind:isShown="true" isRequired :isDisabled="isSaving" />
          <div>
            <employee-info-display v-if="request.employeeInfo.employeeId" :employeeInfo="request.employeeInfo" :vacationMaster="request.vacationMaster" :vacationYear="request.vacationYear" :requestCanBeRaisedForEmployee="requestCanBeRaisedForEmployee" :additionalData="additionalData"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div v-if="requestCanBeRaisedForEmployee" class="row">
      <div class="col">
        <fieldset v-bind:disabled="isSaving">
          <form id="requestForm">
            <div class="form-group row mt-4">
              <label class="col-md-4 font-weight-bold">preferred vacation scheduling option</label>
              <select class="form-control col-md-4 mr-4" v-model="request.vacationMaster.fieldVacationOption" required>
                <option value="" disabled>-- Select preferred vacation scheduling option -- </option>
                <option v-for="option in additionalData.fieldVacationScheduleOptions" :value="option.value">{{ option.description }}</option>
              </select>
              <div class="col-md-3 invalid-feedback">
                Please select a valid option from the list
              </div>
            </div>
            <div class="offset-4 mt-4 pl-2">
              <button class="btn btn-danger d-inline mr-2" @click="resetData" v-bind:disabled="isSaving">Cancel</button>
              <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSaving }" @click="submitRequest" v-bind:disabled="isSaving">Submit<span class="spinner"></span></button>
            </div>
          </form>
        </fieldset>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

    .spin .spinner {
      left: -.6em;
      top: .4em;
      width: 2.5em;
      display: block;
      position: absolute;
    }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'

  import { EMPLOYEE_SEARCH_STARTED_EVENT, EMPLOYEE_SELECTED_EVENT, RequestConstants } from '@/utils/constants'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, vacationMasterService, studyLeaveRequestService, leaveOfAbsenceRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import AppAlert from '@/components/ui/AppAlert'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import EmployeeInfoDisplay from '@/components/vacation/EmployeeInfoDisplay'
  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'

  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/LeaveRequestMixin'
  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'

  export default {
    name: 'FieldVacationOptionScheduleView',
    components: {
      EmployeeAutocomplete,
      EmployeeInfoDisplay,
      StartDateWarningModal,
      AppAlert
    },
    mixins: [AccessControlMixin, LeaveRequestMixin, AlertMixin, ErrorMixin],
    data: () => ({
      request: {
        employeeInfo: {
          employeeId: '',
          employeeNo: '',
          fullName: '',
          employmentDate: '',
          sbuStartDate: '',
          locationName: '',
          locationId: '',
          workScheduleName: '',
          workScheduleId: '',
          employeeTypeId: '',
          employeeTypeName: '',
          supervisorName: '',
          payrollCountryId: null,
          payrollCountry: null,
          alternateSupervisorName: '',
          isFieldEmployee: null
        },
        vacationYear: new Date().getFullYear(),
        vacationMaster: {
          id: null,
          vacationDaysDue: 0,
          travelDays: 0,
          vacationAndTravelDays: 0,
          carriedOverDays: 0,
          unscheduledVacationDays: 0,
          buyOutDays: 0,
          fieldVacationOption: '',
          version: null
        },
        leaveType: 'fieldVacationOption'
      },
      additionalData: {
        vacationYears: [new Date().getFullYear()],
        fieldVacationScheduleOptions: Enums.FieldVacationScheduleOption
      },
      isSaving: false
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      requestCanBeRaisedForEmployee() {
        const currentDate = new Date()
        // An employee has been selected
        if (this.request.employeeInfo.employeeId) {
          // Only regular field employees not on foreign service can raise a field vacation option scheduling request
          if (this.request.employeeInfo.employeeTypeId && this.request.employeeInfo.employeeTypeId === Enums.EmployeeType.Regular.value && this.request.employeeInfo.isFieldEmployee === true) {
            if (this.request.employeeInfo.locationId === Enums.WorkLocation.ForeignService.value) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeBeingOnForeignService, this.request.leaveType)
              return false
            }
            if (!this.appSettings.vacation.allowPreAnniversaryScheduling && (new Date(this.request.employeeInfo.employmentDate)).getFullYear() === (new Date()).getFullYear()) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToPreAnniversary, this.request.leaveType)
              return false
            }
            if (!this.currentUserIsAdminUser && (this.request.vacationYear === currentDate.getFullYear() && (currentDate.getMonth() + 1) > this.appSettings.vacation.fieldScheduleOptionCurrentYearEndMonth) || (this.request.vacationYear === (currentDate.getFullYear() + 1) && (currentDate.getMonth() + 1) < this.appSettings.vacation.nextYearScheduleStartMonth)) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotScheduleRequestDueToClosedWindow, this.request.leaveType)
              return false
            }
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return true
          }
          else {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.leaveType)
            return false
          }
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    watch: {
      'request.employeeInfo.workScheduleId': {
        intermediate: true,
        handler(newValue) {
          if (newValue === Enums.WorkSchedule.WS28x28.value) {
            this.additionalData.fieldVacationScheduleOptions = { Schedule_CashInLieu: Enums.FieldVacationScheduleOption.Schedule_CashInLieu }
          }
          else {
            this.additionalData.fieldVacationScheduleOptions = Enums.FieldVacationScheduleOption
          }
        }
      },
      'request.vacationYear'() {
        this.fetchEmployeeData(this.request.employeeInfo);
      }
    },
    methods: {
      resetData() {
        this.request.employeeInfo.employeeId = ''
        this.request.employeeInfo.employeeNo = ''
        this.request.employeeInfo.fullName = ''
        this.request.employeeInfo.employmentDate = ''
        this.request.employeeInfo.sbuStartDate = ''
        this.request.employeeInfo.employeeTypeId = ''
        this.request.employeeInfo.employeeTypeName = ''
        this.request.employeeInfo.workScheduleId = ''
        this.request.employeeInfo.workScheduleName = ''
        this.request.employeeInfo.locationId = ''
        this.request.employeeInfo.locationName = ''
        this.request.employeeInfo.supervisorName = ''
        this.request.employeeInfo.alternateSupervisorName = ''
        this.request.employeeInfo.payrollCountryId = ''
        this.request.employeeInfo.payrollCountry = ''
        this.request.employeeInfo.isFieldEmployee = null
        this.request.vacationMaster.id = null
        this.request.vacationMaster.vacationDaysDue = 0
        this.request.vacationMaster.travelDays = 0
        this.request.vacationMaster.vacationAndTravelDays = 0
        this.request.vacationMaster.carriedOverDays = 0
        this.request.vacationMaster.unscheduledVacationDays = 0
        this.request.vacationMaster.buyOutDays = 0
        this.request.vacationMaster.fieldVacationOption = ''
        this.request.vacationMaster.version = null
        //this.request.vacationYear = new Date().getFullYear()
      },
      searchEmployees(searchString) {
        const self = this
        self.resetData()
        self.closeMessage()
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            let filteredEmployees = []
            if ((self.currentUser.userRights & Enums.UserRights.EditAllData.value) === Enums.UserRights.EditAllData.value || (self.currentUser.userRights & Enums.UserRights.SystemAdministration.value) === Enums.UserRights.SystemAdministration.value) {
              filteredEmployees = result.data
            }
            else {
              for (const right in Enums.UserRights) {
                const userRight = Enums.UserRights[right]
                if (userRight.employeeSearchFilterProperty && ((self.currentUser.userRights & userRight.value) === userRight.value)) {
                  filteredEmployees = self.filterEmployeesByProperty(result.data, userRight.employeeSearchFilterProperty, self.currentUser[userRight.employeeSearchFilterProperty])
                  break
                }
              }
            }
            filteredEmployees = filteredEmployees.filter(employee => employee.employeeTypeId === Enums.EmployeeType.Regular.value && employee.isFieldEmployee)
            self.searchedEmployees = [...filteredEmployees]
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        })
      },
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        //tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(vacationMasterService.getVacationMasterFor(employee.employeeId, self.request.vacationYear))
        Promise.all(tasks).then((results) => {
          self.resetData()
          //const employee = results[0].data
          self.request.employeeInfo.employeeId = employee.employeeId
          self.request.employeeInfo.employeeNo = employee.employeeNo
          self.request.employeeInfo.fullName = employee.fullName
          self.request.employeeInfo.employmentDate = employee.employmentDate
          self.request.employeeInfo.sbuStartDate = employee.sbuStartDate
          self.request.employeeInfo.employeeTypeName = employee.employeeTypeName
          self.request.employeeInfo.workScheduleId = employee.workScheduleId
          self.request.employeeInfo.workScheduleName = employee.workScheduleName
          self.request.employeeInfo.locationId = employee.locationId
          self.request.employeeInfo.locationName = employee.locationName
          self.request.employeeInfo.employeeTypeId = employee.employeeTypeId
          self.request.employeeInfo.supervisorName = employee.supervisorName
          self.request.employeeInfo.alternateSupervisorName = employee.alternateSupervisorName
          self.request.employeeInfo.payrollCountryId = employee.payrollCountryId
          self.request.employeeInfo.payrollCountry = employee.payrollCountry
          self.request.employeeInfo.isFieldEmployee = employee.isFieldEmployee

          //var vacationMaster = results[1]
          var vacationMaster = results[0]
          if (vacationMaster) {
            self.request.vacationMaster.id = vacationMaster.id
            self.request.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
            self.request.vacationMaster.travelDays = vacationMaster.travelDays
            self.request.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
            self.request.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
            self.request.vacationMaster.unscheduledVacationDays = vacationMaster.unscheduledVacationDays
            self.request.vacationMaster.fieldVacationOption = vacationMaster.fieldVacationOption
            self.request.vacationMaster.buyOutDays = vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule_CashInLieu || employee.workScheduleId === Enums.WorkSchedule.WS28x28.value ? vacationMaster.unscheduleVacationDays : (vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule14_Days ? Math.min(vacationMaster.vacationDays + vacationMaster.travelDays - 14, vacationMaster.unscheduleVacationDays) : 0)
            self.request.vacationMaster.version = vacationMaster.version
          }

        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      submitRequest() {
        const self = this
        const requestForm = document.getElementById('requestForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            "input.form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
        }
        else {
          self.closeMessage()
          self.isSaving = true
          let task = null
          if (!self.request.vacationMaster.id) {
            task = vacationMasterService.createVacationMasterFor(self.request.employeeInfo.employeeId, self.request.vacationYear, self.request.vacationMaster.fieldVacationOption)
          }
          else {
            task = vacationMasterService.updateFieldVacationOptionFor(self.request.employeeInfo.employeeId, self.request.vacationYear, self.request.vacationMaster.fieldVacationOption)
          }
          task.then(() => {
            self.requestState = Enums.RequestState.Submitted
            self.showSuccessMessage('request submitted successfully')
            self.resetData()
          })
            .catch((error) => {
              self.showErrorMessage(error)
            }).finally(() => {
              self.isSaving = false
            })
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchEmployeeData(store.state.userModule.currentUser)
      })
    },
    mounted() {
      if (new Date().getMonth() >= this.appSettings.vacation.nextYearScheduleStartMonth - 1) {
        this.additionalData.vacationYears.push(new Date().getFullYear() + 1)
      }
      eventBus.$on(EMPLOYEE_SEARCH_STARTED_EVENT, this.searchEmployees)
      eventBus.$on(EMPLOYEE_SELECTED_EVENT, this.fetchEmployeeData)
    },
    beforeDestroy() {
      eventBus.$off(EMPLOYEE_SEARCH_STARTED_EVENT)
      eventBus.$off(EMPLOYEE_SELECTED_EVENT)
    }
  }
</script>
